<template>
  <div>
    <v-card class="mx-auto" outlined>
      <ListSkeleton v-if="listSkeleton" />
      <v-card-text
        class="text-h5"
        :class="type ? 'px-2 pt-0' : 'pa-0'"
        v-else-if="calendarList.data"
      >
        <slot name="closeIcon"></slot>
        <v-data-table
          :dense="true"
          class="custom-table mb-0 ma-2 row-pointer"
          :headers="headers"
          :items="calendarList.data"
          :items-per-page="limit"
          :page.sync="page"
          :server-items-length="calendarList.meta.total"
          style="word-break: break-word"
          :options.sync="pagination"
          :search="search"
          :item-class="dynamiColor"
          :footer-props="{
            'items-per-page-options': [10, 20, 30, 40, 50],
          }"
          must-sort
          :sort-by.sync="sortByValue"
          :sort-desc.sync="sortDescValue"
          @dblclick:row="doubleClick"
          @update:sort-by="(val) => buildUrl(val, 'sortKey')"
          @update:sort-desc="(val) => buildUrl(val, 'sortDec')"
        >
          <template v-slot:[`item.start_date`]="{ item }">
            <span
              >{{
                profileById.date_format
                  ? formatDateDDMMYYYY(item.start_date.split("T")[0])
                  : parseDateYYYYMMDD(
                      formatDateDDMMYYYY(item.start_date.split("T")[0])
                    )
              }}
              =
              {{
                profileById.date_format
                  ? formatDateDDMMYYYY(item.end_date.split("T")[0])
                  : parseDateYYYYMMDD(
                      formatDateDDMMYYYY(item.end_date.split("T")[0])
                    )
              }}</span
            >
          </template>
          <template v-slot:[`item.start_time`]="{ item }">
            <span
              >{{ item.start_time.split("T")[1].substring(0, 5) }} =
              {{ item.end_time.split("T")[1].substring(0, 5) }}</span
            >
          </template>
          <template v-slot:top>
            <div class="text-body-2 primary--text mb-2">
              <v-row :dense="true">
                <v-col cols="8"> </v-col>
                <v-col cols="4" class="text-end d-flex">
                  <v-text-field
                    :full-width="false"
                    :dense="true"
                    hide-details="auto"
                    :autofocus="type ? true : false"
                    v-model="search"
                    @input="buildUrl"
                    append-icon="mdi-magnify"
                    :label="$t('common.search')"
                    single-line
                  />
                </v-col>
              </v-row>
            </div>
            <v-divider />
          </template>
          <template v-slot:[`footer.prepend`]>
            <v-row class="d-flex">
              <v-col class="mx-auto" cols="12">
                <span class="pl-3 pt-2"
                  >{{ $t("common.numberOfTotalRows")
                  }}{{ calendarList.meta.total }}</span
                >
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`footer.page-text`]>
            <v-row class="d-flex">
              <v-col class="mx-auto mr-16" cols="4">
                <v-container class="w-100">
                  <v-pagination
                    :total-visible="5"
                    v-model="page"
                    @input="buildUrl"
                    :length="calendarList.meta.last_page"
                  ></v-pagination>
                </v-container>
              </v-col>
            </v-row>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-text class="text-caption text-center" v-else>{{
        $t("common.noDataFound")
      }}</v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ListSkeleton from "@/components/skeleton/ListSkeleton";
import { parseDateYYYYMMDD, formatDateDDMMYYYY } from "@/utils";
export default {
  name: "CalendarList",
  props: ["listSkeleton", "type", "sortByName"],
  components: {
    ListSkeleton,
  },
  data() {
    return {
      page: Number(this.$route.query.page) || 1,
      limit: 10,
      sortByValue: this.$route.query.order_by || null,
      sortDescValue: this.$route.query.sortDec ? true : null,
      pagination: {
        page: Number(this.$route.query.page) || 1,
        itemsPerPage: 10,
        sortBy: [this.$route.query.order_by || "title"],
        sortDesc: this.$route.query.order_by
          ? this.$route.query.sortDec
            ? [true]
            : [false]
          : this.$route.query.sortDec
          ? [true]
          : [false],
      },
      search: this.$route.query.search || "",
    };
  },
  computed: {
    params() {
      return {
        sortBy: this.pagination.sortBy,
        sortDesc: this.pagination.sortDesc,
        page: this.pagination.page,
        itemsPerPage: this.pagination.itemsPerPage,
        query: this.search,
      };
    },
    ...mapGetters({
      calendarList: "calendar/calendarList",
      accessRight: "accessRight",
      profileById: "profile/profileById",
      user: "user",
    }),
    headers() {
      return [
        { text: this.$t("calendar.time"), value: "start_time" },
        { text: this.$t("calendar.title"), value: "title" },
        { text: this.$t("calendar.details"), value: "details" },
        { text: this.$t("calendar.date"), value: "start_date" },
      ];
    },
  },
  watch: {
    type: {
      handler() {
        this.search = "";
        this.page = 1;
        this.limit = 10;
      },
      deep: true,
    },
    params: {
      handler(newVal, oldVal) {
        if (
          JSON.stringify(newVal) !== JSON.stringify(oldVal) ||
          !Object.keys(this.$route.query).length
        )
          this.paginate(false);
      },
      deep: true,
    },
    "$route.query": {
      handler(newVal, oldVal) {
        if (
          JSON.stringify(newVal) !== JSON.stringify(oldVal) ||
          !Object.keys(this.$route.query).length
        )
          this.page = Number(this.$route.query.page) || 1;

        this.sortByValue = this.$route.query.order_by || null;

        this.sortDescValue = this.$route.query.sortDec ? true : null;

        this.search = this.$route.query.search || "";
      },
      deep: true,
      immediate: true,
    },
  },
  async mounted() {
    this.$emit("update:listSkeleton", true);
    this.$store.commit("calendar/SET_CALENDAR_QUERY", this.$route.query);
    if (Object.keys(this.$route.query).length) {
      this.paginate(false);
    } else {
      this.pagination.page = 1;
      this.pagination.itemsPerPage = 10;
      this.pagination.sortBy = ["title"];
      this.pagination.sortDesc = [false];
    }
  },
  methods: {
    parseDateYYYYMMDD,
    formatDateDDMMYYYY,
    buildUrl(val) {
      if (!this.type) {
        let obj = {};
        if (typeof val === "string") {
          this.page = 1;
          obj.page = 1;
          this.params.page = 1;
        }
        obj.order_by = this.sortByValue;
        obj.sortDec = this.sortDescValue;
        obj.search = this.search;
        obj.page = this.page;
        obj = Object.entries(obj).reduce((acc, [key, val]) => {
          if (key === "groupId" && val === null) return acc;
          else if (key === "page" && val === 1) return acc;
          else if (!val) return acc;
          return { ...acc, [key]: val };
        }, {});
        this.$store.commit("calendar/SET_CALENDAR_QUERY", obj);
      }
    },
    async paginate(isSearch = false) {
      const {
        sortBy = this.pagination.sortBy,
        sortDesc = this.pagination.sortDesc,
        page = this.pagination.page,
        itemsPerPage = this.pagination.itemsPerPage,
      } = this.params;
      await this.$store
        .dispatch("calendar/GetCalendar", {
          page: isSearch ? 1 : page,
          limit: itemsPerPage,
          order_by: sortBy?.[0]
            ? sortBy?.[0] + ",id|" + (sortDesc?.[0] ? "desc" : "asc") + ",desc"
            : "title,id|" + (sortDesc?.[0] ? "desc" : "asc") + ",desc",
          calander_user_id: this.user.role.name === "admin" ? "" : this.user.id,
          where_like: this.search
            ? sortBy[0]
              ? sortBy[0] + "|" + this.search
              : sortBy[1]
              ? sortBy[1] + "|" + this.search
              : sortBy[2]
              ? sortBy[2] + "|" + this.search
              : sortBy[3]
              ? sortBy[3] + "|" + this.search
              : "id,details,title|" + this.search
            : "id,details,title|",
        })
        .then(() => this.$emit("update:listSkeleton", false));
    },
    dynamiColor(item) {
      if (item.priority === "high") {
        return "highColor";
      } else if (item.priority === "medium") {
        return "mediumColor";
      } else if (item.priority === "low") {
        return "lowColor";
      }
    },
    doubleClick(event, { item }) {
      const documentTypeMap = {
        Quotation: "/quotation/quotationAction/",
        Order: "/order/orderAction/",
        ProformaInvoice: "/proforma/proformaAction/",
        Delivery: "/delivery/deliveryAction/",
        Invoice: "/invoice/invoiceAction/",
        ReceiptTaxInvoice: "/receiptTaxInvoice/receiptTaxInvoiceAction/",
        CreditInvoice: "/creditInvoice/creditInvoiceAction/",
        ReturnCertificate: "/returnCertificate/returnCertificateAction/",
        Receipt: "/receipt/receiptAction/",
        PurchaseOrder: "/purchaseOrder/purchaseOrderAction/",
        SupplierDeliveryNote:
          "/supplierDeliveryNote/supplierDeliveryNoteAction/",
        SupplierInvoice: "/supplierInvoice/supplierInvoiceAction/",
        SupplierCreditInvoice:
          "/supplierCreditInvoice/supplierCreditInvoiceAction/",
        SupplierPayment: "/supplierPayment/supplierPaymentAction/",
      };

      const path = documentTypeMap[item.document_type];
      if (path) {
        this.$router.push({ path: `${path}${item.document_id}` });
      } else {
        console.log("index module");
      }
    },
  },
};
</script>

<style scoped>
::v-deep .v-pagination__item {
  font-size: 12px;
}
::v-deep .highColor {
  background-color: rgb(243, 111, 111) !important;
}
::v-deep .mediumColor {
  background-color: rgb(202, 251, 255) !important;
}
::v-deep .lowColor {
  background-color: rgb(135, 228, 135) !important;
}
</style>
